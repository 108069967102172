.markdown pre:first-child, .markdown p:first-child, .markdown ul:first-child, .markdown ol:first-child, .markdown blockquote:first-child {
  margin-top: 0;
}

.markdown pre:last-child, .markdown p:last-child, .markdown ul:last-child, .markdown ol:last-child, .markdown blockquote:last-child {
  margin-bottom: 0;
}

.markdown ul {
  padding-left: 20px;
}

.markdown li {
  padding-left: 4px;
}

.cl-logoBox {
  height: 42px;
}

.cl-card, .cl-header {
  gap: 1rem;
}

.cl-page > div:nth-child(3) > div:nth-child(3) button {
  color: #fff;
  background: #fab0071a;
}

.cl-page > div:nth-child(3) div:nth-child(3) button:hover {
  background: #fab007;
}

.cl-page > div:nth-child(3) div:nth-child(3) button:focus {
  box-shadow: none;
}

.cl-page div div button#apple, .cl-page div div button#facebook, .cl-page div div button#google, .cl-page div div button#linkedin {
  background: #fab0071a;
}

.cl-page div div button#apple:focus, .cl-page div div button#facebook:focus, .cl-page div div button#google:focus, .cl-page div div button#linkedin:focus {
  box-shadow: none;
}

.cl-otpCodeFieldInput, .cl- .segment_control_dark .mantine-SegmentedControl-indicator {
  background: #44403c;
}

.segment_control_light .mantine-SegmentedControl-indicator {
  background: #d6d3d1;
}

.segment_control_light .mantine-SegmentedControl-controlActive label {
  color: #666;
}

.segment_control_light div:not(:first-of-type) {
  boorder-style: solid !important;
  border-width: 0 0 0 .0625rem !important;
  border-color: #ccc !important;
}

.test_class {
  color: var(--mantine-color-blue-6);
}

.blue {
  background: #0a53be;
}

.prompt_modifier_select_menus_dark, .prompt_modifier_select_menus_light {
  color: #fff;
  margin-bottom: 0;
}

.prompt_modifier_select_menus_dark .mantine-Select-input::placeholder {
  color: #aaa !important;
}

.prompt_modifier_select_menus_light .mantine-Select-input::placeholder {
  color: #333 !important;
}

.styled_input_container_dark input::placeholder, .styled_input_container_dark textarea::placeholder {
  color: #999 !important;
}

.prompt_input_container .mantine-Input-input:focus, .prompt_input_container .mantine-Input-input:focus-within, .search_input_container > div:first-child:focus-within {
  border: .0625rem solid #fab007;
  border-right-width: .5rem;
}

.search_input_container > div:first-child {
  border: .0625rem solid #999;
}

.prompt_input_container > div:first-child {
  display: block !important;
}

.unset_margin {
  margin: unset;
}

.vertical_align_center {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.index_message {
  color: #fff;
  height: 72vh !important;
}

.input_enabled_dark, .input_enabled_light {
  opacity: 1;
  transition: opacity .6s;
}

.input_disabled_dark, .input_disabled_light {
  cursor: not-allowed;
  opacity: .1;
  transition: opacity .2s;
}

.input_disabled_light {
  cursor: not-allowed;
  opacity: .4;
  transition: opacity .2s;
}

.input_disabled_dark input, .input_disabled_light input {
  pointer-events: none;
}

.disable_fadeout {
  opacity: 1;
  pointer-events: none;
  transition: opacity .5s ease-in-out;
  animation-name: fadeout;
  animation-duration: .5s;
  animation-delay: .1s;
  animation-fill-mode: forwards;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: .5 !important;
  }
}

/*# sourceMappingURL=index.b698eda7.css.map */
