.markdown {
  pre, p, ul, ol, blockquote {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    padding-left: 20px;
  }
  li {
    padding-left: 4px;
  }
}

.cl-logoBox {
  height: 42px;
}

.cl-card {
  gap: 1rem;
}

.cl-header {
  gap: 1rem;
}

.cl-page > div:nth-child(3) > div:nth-child(3) button {
  background: #fab0071a;
  color: #fff;
}

.cl-page > div:nth-child(3) div:nth-child(3) button:hover {
  background: #fab007;
}

.cl-page > div:nth-child(3) div:nth-child(3) button:focus {
  box-shadow: none;
}

.cl-page div div button#apple, .cl-page div div button#facebook, .cl-page div div button#google, .cl-page div div button#linkedin {
  background: #fab0071a;
}

.cl-page div div button#apple:focus, .cl-page div div button#facebook:focus, .cl-page div div button#google:focus, .cl-page div div button#linkedin:focus {
  box-shadow: none;
}

//.cl-avatarBox {
//  box-shadow: rgba(65, 65, 65, 0.7 ) 0px 0px 4px 1px;
//}

.cl-otpCodeFieldInput, .cl-

.segment_control_dark .mantine-SegmentedControl-indicator {
  background: #44403C;
}


.segment_control_light .mantine-SegmentedControl-indicator   {
  background: #D6D3D1;
}
.segment_control_light .mantine-SegmentedControl-controlActive label   {
  color:#666;
}

.segment_control_light div:not(:first-of-type) {
  boorder-style: solid !important;
  border-width: 0 0 0 0.0625rem !important;
  border-color: #ccc !important;
}

.test_class {
  color: var(--mantine-color-blue-6);
}

.blue {
  background: #0a53be;
}

.prompt_modifier_select_menus_dark, .prompt_modifier_select_menus_light {
  color: #fff;
  margin-bottom: 0;
}

.prompt_modifier_select_menus_dark .mantine-Select-input::placeholder {
  color: #aaa !important;
}

.prompt_modifier_select_menus_light .mantine-Select-input::placeholder {
  color: #333 !important;
}

.styled_input_container_dark input::placeholder, .styled_input_container_dark textarea::placeholder {
  color: #999 !important;
}

.styled_input_container_light input::placeholder, .styled_input_container_light textarea::placeholder {
  color: #999 !important;
}


.prompt_input_container .mantine-Input-input:focus,
.prompt_input_container .mantine-Input-input:focus-within,
.search_input_container > div:first-child:focus-within {
  border: 0.0625rem solid #fab007;
  border-right: 0.5rem solid;
  border-right-color: #fab007;
}


.search_input_container > div:first-child {
  border: 0.0625rem solid #999;
}


.prompt_input_container > div:first-child {
  display: block !important;
}

.unset_margin {
  margin: unset;
}

.vertical_align_center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.index_message {
  height: 72vh !important;
  color:#fff;
}

.input_enabled_dark, .input_enabled_light {
  opacity: 1;
  transition: opacity 600ms;
}

.input_disabled_dark, .input_disabled_light {
  cursor: not-allowed;
  transition: opacity 200ms;
  opacity: 0.1;
}

.input_disabled_light {
  cursor: not-allowed;
  transition: opacity 200ms;
  opacity: 0.4;
}

.input_disabled_dark input, .input_disabled_light input {
  pointer-events: none;

}

.disable_fadeout {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  pointer-events: none;
  animation-name: fadeout;
  animation-duration: 0.5s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5 !important;
  }
}